@font-face {
  font-family: "SB Sans Display";
  src: local("SB Sans Display"),
    url(./fonts/sbsansdisplay-regular.otf) format("opentype"),
    url(./fonts/sbsansdisplay-light.otf) format("opentype"),
    url(./fonts/sbsansdisplay-semibold.otf) format("opentype");
}

@font-face {
  font-family: "SB Sans Display";
  src: local("SB Sans Display"),
  url(./fonts/sbsansdisplay-light.otf) format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "SB Sans Text";
  src: local("SB Sans Text"),
  url(./fonts/sbsanstext-regular.otf) format("opentype"),
    url(./fonts/sbsanstext-semibold.otf) format("opentype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
}
